<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'Manage school programs.' : ''"
    />
    <div class="page-section">
      <div :class="containerClass">
        <!-- <div class="card mb-0">
          <div class="card-header"> -->

        <div class="row align-items-center" style="white-space: nowrap">
          <!-- Search -->
          <div class="col-lg-auto">
            <form class="search-form search-form--light d-lg-inline-flex mb-4pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search Programs" v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>

          <!-- Filters and Add Btn -->
          <div
            :class="
              isMobileScreen
                ? 'col-lg d-flex flex-wrap justify-content-between mb-4'
                : 'col-lg d-flex flex-wrap justify-content-end mb-4'
            "
          >
            <b-btn
              v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL"
              v-text="'Reset Order'"
              class="mr-2"
              :style="`width:${isMobileScreen ? '48%' : ''}`"
              @click.prevent="resetOrder"
            />

            <b-btn
              :to="{
                name: get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-add-program' : 'add-program',
              }"
              exact
              variant="primary"
              v-text="'Add Program'"
              :style="`width:${isMobileScreen ? '48%' : ''}`"
            />
          </div>
        </div>
        <page-separator title="" />

        <div class="page-section d-flex justify-content-center" v-if="!programs.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Programs Found</h4>

              <p class="text-muted">Couldn't find any programs. Click below to add a new program.</p>

              <b-btn
                :to="{
                  name: get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-add-program' : 'add-program',
                }"
                exact
                variant="primary"
                class="mt-2"
              >
                Add Program
              </b-btn>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>
                      <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                        >more_vert</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <draggable
            class="row card-group-row mb-lg-12pt home-card draggable-cursor"
            @start="dragging = true"
            @end="updateOrder"
            :list="programs"
            :handle="isMobileScreen ? '#handle' : null"
            :sort="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ? true : false"
          >
            <div v-for="program in programs" :key="program.id" class="col-lg-4 col-md-4 col-sm-6">
              <div class="card card-sm card--elevated p-relative overlay clickable-item" style="height: 90%">
                <div class="justify-content-between align-items-start overlay__content ml-3 my-3 mx-3">
                  <b-badge
                    :class="
                      'border border-' +
                      (program.status === PROGRAM_STATUSES.OFFERING ? `success badge-success` : `danger badge-danger`)
                    "
                    variant="none"
                  >
                    {{ $t(`programStatusOptions.${program.status}`) }}
                  </b-badge>
                  <i
                    class="fa fa-align-justify"
                    style="font-size: 1.5rem; color: black"
                    id="handle"
                    v-if="isMobileScreen"
                  ></i>
                </div>

                <span
                  class="js-image d-flex justify-content-center clickable-item"
                  data-position="left"
                  :data-height="168"
                  @click.prevent="editProgram(program.id)"
                >
                  <img :src="program.image" alt="cna" class="career-card-img" />
                </span>

                <div class="card-body">
                  <div class="d-flex">
                    <a class="flex" href="#" @click.prevent="editProgram(program.id)">
                      <div class="card-title">{{ program.title }}</div>
                      <div class="row ml-0">
                        <small
                          class="text-50 font-weight-bold d-flex align-items-center"
                          :class="{ 'mb-4pt': get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN }"
                        >
                          {{ $t(`programTypeOptions.${program.type}`) }}
                        </small>

                        <template v-if="program.pre_enrollment_enabled">
                          <small class="text-50 font-weight-bold mx-1">|</small>
                          <small class="text-50 font-weight-bold"> Requires Pre-enrollment </small>
                        </template>
                      </div>

                      <small
                        class="text-50 font-weight-bold mb-4pt d-flex align-items-center"
                        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
                      >
                        <i class="mr-1 material-icons icon-16pt">account_balance</i>{{ program.school.name }}
                      </small>
                    </a>
                    <b-dropdown variant="flush" toggle-class="text-muted" no-caret right>
                      <template #button-content>
                        <i class="material-icons icon-24pt card-course__icon-favorite">more_vert</i>
                        <span class="sr-only">Menu</span>
                      </template>
                      <b-dropdown-item href="#" @click.prevent="editProgram(program.id)">
                        <i class="mr-1 material-icons">edit</i> Edit Details
                      </b-dropdown-item>

                      <b-dropdown-item href="#" @click.prevent="duplicateProgram(program.id, program.title)">
                        <i class="mr-1 material-icons">content_copy</i> Duplicate Program
                      </b-dropdown-item>

                      <b-dropdown-item
                        href="#"
                        @click.prevent="
                          updateOfferStatus(
                            program.id,
                            program.status === PROGRAM_STATUSES.OFFERING
                              ? PROGRAM_STATUSES.NOT_OFFERED
                              : PROGRAM_STATUSES.OFFERING
                          )
                        "
                      >
                        <i class="mr-1 material-icons">
                          {{ program.status === PROGRAM_STATUSES.OFFERING ? 'toggle_off' : 'toggle_on' }}
                        </i>
                        {{ program.status === PROGRAM_STATUSES.OFFERING ? 'Mark as Not Offered' : 'Mark as Offering' }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        href="#"
                        :to="{ name: 'classes-management-list', query: { program: program.id } }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> class</i>Manage Classes</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        :to="{
                          name: 'manage-wait-list',
                          query: { program: program.id },
                          params: { name: program.title },
                        }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> av_timer</i>Manage Waitlist</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        :to="{
                          name: 'manage-link-product-list',
                          query: { program: program.id },
                          params: { name: program.title },
                        }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> local_mall</i>Manage Products</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        :to="{
                          name: 'manage-registration-form',
                          params: { id: program.id, name: program.title, form: 'enrollment' },
                        }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> help</i>Manage Registration Form</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        :to="{
                          name: 'manage-pre-enrollment-form',
                          params: { id: program.id, name: program.title, form: 'pre_enrollment' },
                        }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> format_align_justify</i>Manage Pre-enrollment
                        Form</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        :to="{ name: 'manage-info-list', query: { program: program.id } }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> contact_mail</i>Manage Inquiries</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        :to="{
                          name: 'prg-faq-management-list',
                          params: { programId: program.id, name: program.title },
                        }"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons"> question_answer</i>Manage FAQs</b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        @click.prevent="copyProgramLink(program.slug)"
                        v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                      >
                        <i class="mr-1 material-icons">content_copy</i> Copy Program Link
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
          <!-- </div> -->
        </b-skeleton-wrapper>

        <pagination
          v-if="totalPrograms > programs.length"
          v-model="currentPage"
          :total-rows="totalPrograms"
          :per-page="perPage"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { get, debounce, map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Pagination from '../../components/Ui/Pagination.vue';
import Page from '@/components/Page.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../common/constants';
import { USER_ROLES, PROGRAM_STATUSES, USER_ROLE_TYPES, PAYOUT_ACC_STATUS } from '../../common/constants';
import draggable from 'vuedraggable';
export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, draggable },
  extends: Page,

  data() {
    return {
      title: 'Manage Programs',
      linkedStatus: false,
      isPayoutStatusLoading: false,
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalPrograms: 0,
      programs: [],
      typeOptions: [
        { value: 'virtual', text: 'Virtual' },
        { value: 'onsite', text: 'Onsite' },
        { value: 'blended', text: 'Hybrid' },
      ],
      USER_ROLES,
      PROGRAM_STATUSES,
      USER_ROLE_TYPES,
      searchTerm: '',
      PAYOUT_ACC_STATUS,
      dragging: false,
      windowWidth: window.innerWidth,
      isReseting: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('Programs'), active: true },
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('program', [
      'getAllPrograms',
      'updateStatus',
      'createDuplicateProgram',
      'updateProgramOrder',
      'getResetOrderPrograms',
    ]),
    get,
    async resetOrder() {
      this.isReseting = true;
      try {
        await this.getResetOrderPrograms();
        this.fetchPrograms();
        this.makeToast({ variant: 'success', msg: 'Order reset Successfully!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isReseting = false;
    },
    async updateOrder() {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN) return;
      this.dragging = false;
      const data = {
        programs: map(this.programs, (id, order_num) => ({
          id: id.id,
          order_num: order_num + 1,
        })),
      };
      try {
        await this.updateProgramOrder(data);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async duplicateProgram(id, title) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to duplicate program #${title}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.isLoading = true;
          await this.createDuplicateProgram({ program_id: id });
          this.fetchPrograms();
          this.makeToast({ variant: 'success', msg: 'Duplicate Program created!' });
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async copyProgramLink(slug) {
      try {
        await navigator.clipboard.writeText(
          `${get(this.getLoggedInUser, 'linked_entity.subdomain.subdomain_url')}/programs/${slug}`
        );

        this.makeToast({ variant: 'success', msg: 'Program link copied Successfully!' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    editProgram(id) {
      this.$router.push({
        name: get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-edit-program' : 'edit-program',
        params: { id: id },
      });
    },
    async updateOfferStatus(id, status) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to ${
            status === PROGRAM_STATUSES.NOT_OFFERED ? 'stop' : 'start'
          } offering the selected program.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updateStatus({ id: id, data: { status } });
          this.fetchPrograms();
          this.makeToast({ variant: 'success', msg: 'Program Status Successfully Updated!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchPrograms(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllPrograms({
        ...(this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN && { limit: this.perPage }),
        ...(this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN && { offset: (pageNum - 1) * this.perPage }),

        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.programs =
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN ? response.data.results : response.data;
      this.currentPage = pageNum;
      this.totalPrograms =
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN ? response.data.count : response.data.length;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPrograms(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPrograms();
    },

    onSearch() {
      this.debouncedSearchPrograms(this);
    },

    debouncedSearchPrograms: debounce((vm) => {
      vm.fetchPrograms();
    }, 500),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    this.fetchPrograms();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
