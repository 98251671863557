<template>
  <div>
    <div class="card-header">
      <div class="row align-items-center" style="white-space: nowrap">
        <div class="col-lg-auto">
          <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
            <b-form-input class="w-lg-auto" placeholder="Search Classes" :value="searchTerm" @input="onSearch" />

            <b-btn variant="flush" type="submit">
              <md-icon v-text="'search'" />
            </b-btn>
          </form>
          <b-btn
            variant="secondary"
            @click.prevent="updateShowFilters(true)"
            :class="isTabSmallScreen ? '' : 'ml-2'"
            v-if="!showFilters"
            size="sm"
          >
            <i class="fas fa-sliders-h mr-1" />Show {{ !areFiltersApplied ? 'Filters' : '' }}
          </b-btn>
          <b-btn variant="light" class="ml-2" @click.prevent="updateShowFilters(false)" v-if="showFilters" size="sm">
            <i class="fas fa-eye-slash mr-1" />Hide {{ !areFiltersApplied ? 'Filters' : '' }}
          </b-btn>
          <b-btn variant="light" class="ml-2" @click.prevent="clearFilters" v-if="areFiltersApplied" size="sm">
            <i class="fas fa-times mr-1" />Clear
          </b-btn>
        </div>

        <div class="col-lg d-flex flex-wrap justify-content-end">
          <div class="col-lg-7 px-0" :class="isTabSmallScreen ? 'mt-2 mb-2' : 'mx-0'">
            <v-select
              id="program"
              class="form-control v-select-custom"
              label="title"
              :value="program"
              :reduce="program => program.id"
              placeholder="Select Program"
              :options="allPrograms"
              :loading="areProgramsLoading"
              @input="onFilterApplied"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>

              <template slot="option" slot-scope="option">
                <div class="d-flex align-items-center">
                  <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                    <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                    <md-icon v-else>local_library</md-icon>
                  </fmv-avatar>
                  <span>{{ option.title }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ truncate(option.title, { length: 20 }) }}
              </template>
            </v-select>
          </div>

          <b-btn class="ml-2" :to="{ name: 'add-class' }" exact variant="primary" v-text="'Add Class'" />
        </div>
      </div>

      <transition name="slide">
        <!-- <div v-if="showFilters" class="mt-2"> -->
        <div class="row mb-2 mx-0 w-100 mt-2" v-if="showFilters">
          <div class="col-sm-4 col-md-4 mb-2 pl-0 pr-1">
            <div class="custom-select-icon" style="min-width: 130px">
              <b-form-select
                class="custom-select-icon__select"
                :value="schedule"
                placeholder="Select Schedule"
                :options="SELECT_SCHEDULE_TYPE_OPTIONS"
                @input="filterSchedule"
              >
                ></b-form-select
              >
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 mb-2 pl-0 pr-1">
            <div class="custom-select-icon" style="min-width: 130px">
              <b-form-select
                class="custom-select-icon__select"
                :value="status"
                placeholder="Select Status"
                :options="CLASS_STATUS_OPTIONS"
                @input="filterStatus"
              >
                ></b-form-select
              >
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
          </div>
          <div class="col-sm-4 col-md-4 mb-2 px-1">
            <v-select
              label="id"
              :options="allLocations"
              class="form-control v-select-custom"
              :value="location"
              :reduce="lc => lc.id"
              placeholder="Select Location"
              :loading="areLocationsLoading"
              @input="filterLocations"
              :filter-by="filterByLocation"
            >
              <template slot="option" slot-scope="option"> {{ get(option, 'city') }} </template>
              <template slot="selected-option" slot-scope="option"> {{ get(option, 'city') }} </template>
            </v-select>
          </div>
          <div class="col-sm-4 col-md-4 mb-2 pl-0 pr-1">
            <div class="custom-select-icon" style="min-width: 130px">
              <b-form-select
                class="custom-select-icon__select"
                :value="type"
                placeholder="Select Type"
                :options="typeOptions"
                @input="filterType"
              >
                ></b-form-select
              >
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
          </div>
        </div>

        <!-- </div> -->
      </transition>
    </div>

    <b-table
      :fields="tableColumns"
      :items="classes"
      :busy="isLoading"
      head-variant="light"
      class="table-nowrap"
      hover
      responsive
      no-local-sorting
      @sort-changed="onSortChanged"
    >
      <template #cell(menu)="data">
        <div class="text-center">
          <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
            <template #button-content>
              <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
              <span class="sr-only">Menu</span>
            </template>

            <b-dropdown-item @click.prevent="openInitiateRegistrationModal(data.item)">
              <md-icon class="text-info">person_add</md-icon> Initiate Registration
            </b-dropdown-item>

            <b-dropdown-item @click.prevent="openRegisterModal(data.item)">
              <md-icon class="text-info">visibility</md-icon> View Registrations
            </b-dropdown-item>

            <b-dropdown-item @click.prevent="duplicateClass(data.item.id)">
              <md-icon class="text-info">content_copy</md-icon> Duplicate Class
            </b-dropdown-item>

            <b-dropdown-item
              :to="{
                name: 'edit-class',
                params: { id: data.item.id }
              }"
            >
              <md-icon class="text-info">edit</md-icon> Edit Details
            </b-dropdown-item>

            <b-dropdown-item
              @click.prevent="
                updateOfferStatus(
                  data.item.id,
                  data.item.status === CLASS_STATUSES.REGISTRATION_OPEN
                    ? CLASS_STATUSES.REGISTRATION_CLOSED
                    : CLASS_STATUSES.REGISTRATION_OPEN
                )
              "
            >
              <md-icon
                :class="data.item.status === CLASS_STATUSES.REGISTRATION_OPEN ? 'text-danger' : 'text-success'"
                >{{ data.item.status === CLASS_STATUSES.REGISTRATION_OPEN ? 'block' : 'check_circle_outline' }}</md-icon
              >
              {{ data.item.status === CLASS_STATUSES.REGISTRATION_OPEN ? 'Close Registration' : 'Open Registrations' }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #cell(id)="data"> #{{ data.item.id }} </template>
      <template #cell(program)="data">
        <div class="d-flex align-items-center">
          <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
            <b-img
              :src="data.item.program.image"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-if="data.item.program.image"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>
          <router-link
            :to="{
              name: 'edit-class',
              params: { id: data.item.id }
            }"
            class="text-primary"
          >
            {{ truncate(data.item.program.title, { length: 30 }) }}
          </router-link>
        </div>
      </template>

      <template #cell(start_date)="data"
        >{{ data.item.start_date ? formatDateSimple(data.item.start_date) : 'Self-Paced' }}
      </template>

      <template #cell(status)="data">
        <b-badge
          :class="
            'border border-' +
              (data.item.status === CLASS_STATUSES.REGISTRATION_OPEN ? `success badge-success` : `danger badge-danger`)
          "
          variant="none"
          v-b-popover.hover.top
          :title="$t(`classStatusDesc.${data.item.status}`)"
        >
          {{ $t(`classStatusOptions.${data.item.status}`) }}
        </b-badge>
      </template>

      <template #cell(schedule_type)="data">
        {{ data.item.schedule_type ? $t(`scheduleTypes.${data.item.schedule_type}`) : '' }}
      </template>

      <template #cell(instructor_name)="data"> {{ data.item.instructor_name }}</template>
      <template #cell(reg_count)="data">
        <i v-if="areRegisterCountsLoading">Loading...</i>
        <span v-else>
          <a
            href="#"
            @click.prevent="openRegisterModal(data.item)"
            class="text-primary"
            v-b-popover.hover.right
            title="View Registered Students"
            >{{ $n(get(registerCountByClass, `${data.item.id}.reg_count`, 0)) }}</a
          ><span v-if="data.item.limited_seats"
            >/{{
              $n(get(registerCountByClass, `${data.item.id}.reg_count`, 0) + get(data.item, 'available_seats', 0))
            }}</span
          >
        </span>
      </template>

      <template #cell(total_weeks)="data"> {{ $n(data.item.total_weeks, 'singleDecimal', 'en-US') }}</template>
      <template #cell(total_hours)="data"> {{ $n(data.item.total_hours, 'singleDecimal', 'en-US') }}</template>
    </b-table>
    <div class="card-footer">
      <pagination
        v-model="currPage"
        :total-rows="totalClasses"
        :per-page="perPage"
        @change="onPageChange"
        aria-controls="classes-table"
      />
    </div>
    <registered-students-modal
      :show-modal="modals.showRegisterModal"
      :selected-class="modals.selectedClass"
      @close="hideRegisterModal"
    >
    </registered-students-modal>

    <initiate-registration-modal
      :show-modal="modals.showInitiateRegistrationModal"
      :selected-class="modals.selectedClass"
      @close="hideInitiateRegistrationModal"
    >
    </initiate-registration-modal>
    <manual-registration-modal
      :show-modal.sync="modals.showManualRegistrationModal"
      :selected-class="modals.selectedClass"
      v-if="modals.showManualRegistrationModal"
      @close="hideManualRegistrationModal"
      @closeAndShowModal="openInitiateRegistrationModal"
    >
    </manual-registration-modal>
  </div>
</template>

<script>
import Pagination from '../../../components/Ui/Pagination.vue';
import RegisteredStudentsModal from './RegisteredStudents/RegisteredStudentsModal.vue';
import ManualRegistrationModal from './Manual Registration/ManualRegistrationModal.vue';
import { formatDateSimple } from '../../../common/utils';
import { CLASS_STATUSES, SELECT_SCHEDULE_TYPE_OPTIONS, CLASS_STATUS_OPTIONS } from '../../../common/constants';
import { mapActions } from 'vuex';
import { get, truncate } from 'lodash';
import InitiateRegistrationModal from './InitiateRegistration/InitiateRegistrationModal.vue';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
export default {
  props: {
    showFilters: { type: Boolean, default: false },
    status: { type: String, default: null },
    type: { type: String, default: null },
    schedule: { type: String, default: null },
    classes: { type: Array, default: () => [] },
    totalClasses: { type: Number, default: 0 },
    isLoading: { type: Boolean, default: false },
    perPage: { type: Number, default: 0 },
    searchTerm: { type: String, default: '' },
    program: { type: Number, default: null },
    location: { type: Number, default: null },
    areProgramsLoading: { type: Boolean, default: false },
    areLocationsLoading: { type: Boolean, default: false },
    areRegisterCountsLoading: { type: Boolean, default: false },
    allPrograms: { type: Array, default: () => [] },
    allLocations: { type: Array, default: () => [] },
    registerCounts: { type: Array, default: () => [] },
    registerCountByClass: { type: Object, default: null }
  },

  components: {
    Pagination,
    RegisteredStudentsModal,
    ManualRegistrationModal,
    InitiateRegistrationModal,
    vSelect,
    FmvAvatar
  },
  data() {
    return {
      SELECT_SCHEDULE_TYPE_OPTIONS,
      CLASS_STATUS_OPTIONS,
      CLASS_STATUSES,
      currPage: 1,

      modals: {
        showRegisterModal: false,
        selectedClass: {},
        showManualRegistrationModal: false,
        showInitiateRegistrationModal: false
      },
      windowWidth: window.innerWidth,
      formatDateSimple,
      typeOptions: [
        { value: null, text: 'Select Type' },
        { value: 'scheduled', text: 'Scheduled' },
        { value: 'self_paced', text: 'Self-Paced' }
      ]
    };
  },

  computed: {
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    areFiltersApplied() {
      return this.program || this.location || this.status || this.schedule || this.type;
    },
    tableColumns() {
      return [
        {
          key: 'menu',
          tdClass: 'text-left'
        },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'program', label: 'Program' },
        {
          key: 'start_date',
          label: 'Start Date',

          sortable: true
        },

        { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'reg_count', label: 'Enrolled', sortable: true },
        { key: 'schedule_type', label: 'Schedule' },
        { key: 'class_location', label: 'Location', formatter: value => get(value, 'city', '') }
      ];
    }
  },
  methods: {
    ...mapActions('classes', ['getAllClasses', 'updateStatus', 'getClass']),

    get,
    truncate,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    clearFilters() {
      this.$emit('clear');
    },
    updateShowFilters(value) {
      this.$emit('update:showFilters', value);
    },
    filterStatus(value) {
      this.$emit('update:status', value);
      this.$emit('filter');
    },
    filterType(value) {
      this.$emit('update:type', value);
      this.$emit('filter');
    },
    filterSchedule(value) {
      this.$emit('update:schedule', value);
      this.$emit('filter');
    },
    duplicateClass(id) {
      this.$emit('duplicate', id);
    },
    onPageChange(val) {
      this.$emit('pageChange', val);
    },
    onSortChanged(context) {
      this.$emit('onSort', context);
    },
    editClass(row) {
      this.$emit('edit', row);
    },
    onFilterApplied(value) {
      this.$emit('update:program', value);
      this.$emit('filter');
    },
    filterByLocation(option, label, search) {
      this.$emit('filterLocations', option, label, search);
    },
    filterLocations(value) {
      this.$emit('update:location', value);
      this.$emit('filter');
    },
    onSearch(val) {
      this.$emit('update:searchTerm', val);
      this.$emit('search');
    },
    openRegisterModal(item) {
      this.modals.showRegisterModal = true;
      this.modals.selectedClass = item;

      // Adding registrations class id to route.
      this.$router.push({
        name: 'classes-management-list',
        query: { ...this.$route.query, registrations: item.id }
      });
    },
    hideRegisterModal() {
      this.modals.showRegisterModal = false;
      this.modals.selectedClass = {};

      // Removing registrations class id from route.
      this.$router.replace({ query: { ...this.$route.query, registrations: undefined } });
    },

    openInitiateRegistrationModal(item) {
      this.modals.showInitiateRegistrationModal = true;
      this.modals.selectedClass = item;
      this.$router.replace({ name: 'classes-management-list', query: { classId: item.id } });
    },
    hideInitiateRegistrationModal() {
      this.modals.showInitiateRegistrationModal = false;

      this.modals.selectedClass = {};

      // this.$emit('fetch');
    },

    openManualRegistrationModal(item) {
      this.modals.showManualRegistrationModal = true;
      this.modals.selectedClass = item;
    },
    hideManualRegistrationModal() {
      this.modals.showManualRegistrationModal = false;
      this.modals.selectedClass = {};

      this.$emit('fetch');
    },
    async updateOfferStatus(id, status) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to ${
            status === CLASS_STATUSES.REGISTRATION_OPEN ? 'open' : 'close'
          } registration of the class.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.updateStatus({ id: id, data: { status } });
          this.$emit('fetch');
          this.makeToast({ variant: 'success', msg: 'Class Status Successfully Updated!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    }
  },

  async mounted() {
    if (this.$route.query.classId) {
      const resp = (await this.getClass(this.$route.query.classId)).data;
      this.openInitiateRegistrationModal(resp);
    }

    if (this.$route.query.registrations) {
      this.openRegisterModal({ id: parseInt(this.$route.query.registrations) });
    }
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
