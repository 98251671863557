<template>
  <div :class="containerClass">
    <page-header
      title="Configure Home Page Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Craft an engaging and effective homepage for your site.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="(school) => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillLogo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Top Banner -->

      <page-separator>
        <b-link v-b-toggle="'top-banner-section'" class="accordion__toggle p-0">
          <span class="flex">Banner</span>
          <md-icon class="accordion__toggle-icon">
            {{ topBannerVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="top-banner-section"
        accordion="topBannerAccordionId"
        :visible="topBannerVisible"
        class="accordion__menu"
        @hide="topBannerVisible = false"
        @show="topBannerVisible = true"
      >
        <b-form-group
          label="Banner Image"
          label-for="banner_image"
          label-class="form-label"
          class="row-align-items-center"
        >
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isImgUploading">...</span>
              <b-img
                :src="homepageConfig.banner_img_url"
                class="img-fluid"
                width="40"
                alt="Image"
                v-else-if="homepageConfig.banner_img_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="(e) => setImage(e, 'banner_image')"
              :isUploading="isImgUploading"
              :isRequired="false"
              placeholder="Select Banner Image"
            />
          </b-media>
        </b-form-group>

        <b-form-group label-class="form-label">
          <b-form-checkbox id="banner_overlay" v-model="homepageConfig.bannerOverlay" switch>
            Enable banner overlay?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Text Alignment" label-for="text_alignment" label-class="form-label">
          <div class="row" style="margin-left: -10px">
            <div
              @click.prevent="
                () => {
                  bannerSelectedAlignment = option.value;
                }
              "
              v-for="option in textAlignOptions"
              :key="option.value"
              class="clickable-item py-2 mx-2"
            >
              <div class="card" :class="bannerSelectedAlignment === option.value ? 'border-outline-primary-btn' : ''">
                <md-icon
                  v-b-popover.hover.right
                  :title="option.text"
                  style="font-size: 30px"
                  class=""
                  :class="bannerSelectedAlignment === option.value ? 'text-primary' : ''"
                  >{{ option.icon }}</md-icon
                >
              </div>
            </div>
          </div>
        </b-form-group>

        <b-form-group label="Title" label-for="title" label-class="form-label">
          <b-form-input id="title" placeholder="Enter Title" v-model="homepageConfig.title" required />
        </b-form-group>

        <b-form-group label="Description" label-for="desc" label-class="form-label">
          <b-form-textarea id="desc" rows="6" v-model="homepageConfig.description" required></b-form-textarea>
        </b-form-group>

        <b-form-group label-class="form-label">
          <b-form-checkbox id="bannerCtaBtn" v-model="homepageConfig.ctaBtn" switch> show CTA button? </b-form-checkbox>
        </b-form-group>

        <div v-if="homepageConfig.ctaBtn">
          <b-form-group label="CTA Title" label-for="title" label-class="form-label">
            <b-form-input id="cta_title" placeholder="Enter CTA Title" v-model="homepageConfig.ctaTitle" required />
          </b-form-group>

          <b-form-group label-class="form-label">
            <b-form-checkbox id="bannerCtaLinkBtn" v-model="homepageConfig.ctaLinkStatus" switch>
              Use default enrollment link
              <span style="color: #c5c5c5d6">
                ({{ get(getLoggedInUser, 'linked_entity.subdomain.subdomain_url') }})
              </span>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="!homepageConfig.ctaLinkStatus"
            label="Enrollment Link"
            label-for="enrollment_link"
            label-class="form-label"
          >
            <b-form-input id="cta_link" placeholder="Enter enrollment link" v-model="homepageConfig.ctaLink" required />
          </b-form-group>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'stats-section'" class="accordion__toggle p-0">
          <span class="flex">Stats</span>
          <md-icon class="accordion__toggle-icon">
            {{ statsVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="stats-section"
        accordion="statsAccordionId"
        :visible="statsVisible"
        class="accordion__menu"
        @hide="statsVisible = false"
        @show="statsVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="stats" v-model="homepageConfig.visibleSections.stats" switch>
            Enable this section
          </b-form-checkbox>
        </b-form-group>

        <div v-if="homepageConfig.visibleSections.stats">
          <b-form-group label="Stats Title" label-for="title" label-class="form-label">
            <b-form-input id="stats_title" placeholder="Stats" v-model.trim="homepageConfig.stats_title" />
          </b-form-group>

          <b-form-group label="Stats Description" label-for="stats_desc" label-class="form-label">
            <b-form-textarea id="stats_desc" rows="6" v-model="homepageConfig.stats_desc" required></b-form-textarea>
          </b-form-group>

          <b-row v-for="(st, index) in $v.stats.$each.$iter" :key="index" class="mb-2">
            <b-form-group label="Title" label-for="title" label-class="form-label" class="col-md-6">
              <b-form-input
                id="title"
                placeholder="Enter Title"
                v-model.trim="st.title.$model"
                :state="!st.title.required && st.$dirty ? false : null"
              />

              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Count" label-for="count" label-class="form-label" class="col-md-4">
              <b-form-input
                id="count"
                type="number"
                placeholder="Enter count"
                v-model.trim="st.count.$model"
                :state="!st.count.required && st.$dirty ? false : null"
              />

              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <div class="mt-4 pl-2">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addStat(index)">
                <md-icon>add</md-icon>
              </a>
              <a href="#" class="btn btn-danger ml-2 btn-md" @click.prevent="removeStat(index)" v-if="stats.length > 1">
                <md-icon>close</md-icon>
              </a>
            </div>
          </b-row>
        </div>
      </b-collapse>

      <!-- Features -->

      <page-separator>
        <b-link v-b-toggle="'features-section'" class="accordion__toggle p-0">
          <span class="flex">Features</span>
          <md-icon class="accordion__toggle-icon">
            {{ featuresVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="features-section"
        accordion="featuresAccordionId"
        :visible="featuresVisible"
        class="accordion__menu"
        @hide="featuresVisible = false"
        @show="featuresVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="features" v-model="homepageConfig.visibleSections.features" switch>
            Enable this section
          </b-form-checkbox>
        </b-form-group>

        <div v-if="homepageConfig.visibleSections.features">
          <b-form-group label="Feature Title" label-for="feature_title" label-class="form-label">
            <b-form-input id="feature_title" placeholder="Features" v-model.trim="homepageConfig.feature_title" />
          </b-form-group>

          <div v-for="(fe, index) in $v.features.$each.$iter" :key="index" class="mb-2">
            <div class="d-flex justify-content-end">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addFeat(index)"> Add Feature </a>
              <a
                href="#"
                class="btn btn-danger ml-2 btn-md"
                @click.prevent="removeFeat(index)"
                v-if="features.length > 1"
              >
                Remove Feature
              </a>
            </div>

            <div>
              <page-separator :title="`Feature #${+index + 1}`" />

              <b-form-group
                label="Image"
                label-for="image_url"
                label-class="form-label"
                class="row-align-items-center col-md-12"
              >
                <b-media class="align-items-center" vertical-align="center">
                  <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                    <span v-if="featuresFile[index].isImgUploading">...</span>
                    <b-img
                      :src="fe.image_url.$model"
                      class="img-fluid"
                      width="40"
                      alt="Image"
                      v-else-if="fe.image_url.$model"
                    />
                    <i class="fas fa-university fa-lg" v-else></i>
                  </fmv-avatar>

                  <image-uploader
                    @image="(e) => setFeaturesImage(e, index)"
                    :isUploading="featuresFile[index].isImgUploading"
                    :isRequired="false"
                    placeholder="Select Feature Image"
                  />
                </b-media>
              </b-form-group>

              <b-form-group label="Title" label-for="title" label-class="form-label" class="col-md-12">
                <b-form-input
                  id="title"
                  placeholder="Feature"
                  v-model.trim="fe.title.$model"
                  :state="!fe.title.required && fe.$dirty ? false : null"
                />

                <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Description" label-for="description" label-class="form-label" class="col-md-12">
                <b-form-textarea
                  id="description"
                  :rows="5"
                  placeholder="Enter description "
                  v-model.trim="fe.$model.description"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'expertise-section'" class="accordion__toggle p-0">
          <span class="flex">Expertise</span>
          <md-icon class="accordion__toggle-icon">
            {{ expertiseVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="expertise-section"
        accordion="expertiseAccordionId"
        :visible="expertiseVisible"
        class="accordion__menu"
        @hide="expertiseVisible = false"
        @show="expertiseVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="expertise" v-model="homepageConfig.visibleSections.expertise" switch>
            Enable this section
          </b-form-checkbox>
        </b-form-group>

        <div v-if="homepageConfig.visibleSections.expertise">
          <b-form-group label="Title" label-for="title" label-class="form-label">
            <b-form-input
              id="expertise_title"
              placeholder="Enter Title"
              v-model.trim="homepageConfig.expertise_title"
            />
          </b-form-group>
          <b-form-group label="Description" label-for="expertise_desc" label-class="form-label">
            <b-form-textarea
              id="expertise_desc"
              rows="6"
              v-model="homepageConfig.expertise_desc"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-row v-for="(st, index) in $v.tags.$each.$iter" :key="index" class="mb-2 p-2">
            <span class="col-md-1 col-1 form-label mt-2 d-flex justify-content-end">Tag</span>

            <b-form-group label-class="form-label" class="col-md-9 col-8">
              <b-form-input
                :id="`tag-${index}`"
                placeholder="Enter Tag"
                v-model.trim="st.title.$model"
                :state="!st.title.required && st.$dirty ? false : null"
              />

              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <div class="col-md-2 col-2 d-flex align-items-start">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addTag(index)">
                <md-icon>add</md-icon>
              </a>
              <a href="#" class="btn btn-danger ml-2 btn-md" @click.prevent="removeTag(index)" v-if="tags.length > 1">
                <md-icon>close</md-icon>
              </a>
            </div>
          </b-row>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'programs-section'" class="accordion__toggle p-0">
          <span class="flex">Offered Programs</span>
          <md-icon class="accordion__toggle-icon">
            {{ programsVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="programs-section"
        accordion="programsAccordionId"
        :visible="programsVisible"
        class="accordion__menu"
        @hide="programsVisible = false"
        @show="programsVisible = true"
      >
        <b-form-group label="Layout" label-for="prog_layout" label-class="form-label">
          <div class="row" style="margin-left: -22px">
            <div class="col-md-4" v-for="layout in progLayoutOptions" :key="layout.image">
              <div class="layout-hover column text-center clickable-item">
                <figure>
                  <img
                    width="100%"
                    height="125px"
                    :src="getImgUrl(layout.image)"
                    @click.prevent="() => (progLayout = layout.value)"
                    :alt="progLayout"
                    :class="progLayout === layout.value ? 'border-outline-primary' : ''"
                    :style="progLayout === layout.value ? '' : 'border: #f2f2f2 2px solid; border-radius: 10px;'"
                  />
                </figure>
              </div>
            </div>
          </div>
        </b-form-group>

        <b-form-group label="Text Alignment" label-for="text_alignment" label-class="form-label">
          <div class="row" style="margin-left: -10px">
            <div
              @click.prevent="
                () => {
                  programSelectedAlignment = option.value;
                }
              "
              v-for="option in textAlignOptions"
              :key="option.value"
              class="clickable-item py-2 mx-2"
            >
              <div class="card" :class="programSelectedAlignment === option.value ? 'border-outline-primary-btn' : ''">
                <md-icon
                  v-b-popover.hover.right
                  :title="option.text"
                  style="font-size: 30px"
                  class=""
                  :class="programSelectedAlignment === option.value ? 'text-primary' : ''"
                  >{{ option.icon }}</md-icon
                >
              </div>
            </div>
          </div>
        </b-form-group>

        <b-form-group label="Title" label-for="prog_title" label-class="form-label">
          <b-form-input
            id="prog_title"
            placeholder="Enter Program Title"
            v-model="homepageConfig.prog_title"
            required
          />
        </b-form-group>

        <b-form-group label="Number of Programs" label-for="programsCount" label-class="form-label">
          <b-form-input
            id="programsCount"
            type="number"
            min="0"
            placeholder="Enter number of programs"
            v-model="programsCount"
          />
        </b-form-group>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'programsAndClasses-section'" class="accordion__toggle p-0">
          <span class="flex">Upcoming Classes</span>
          <md-icon class="accordion__toggle-icon">
            {{ classesVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="programsAndClasses-section"
        accordion="programsAndClassesAccordionId"
        :visible="classesVisible"
        class="accordion__menu"
        @hide="classesVisible = false"
        @show="classesVisible = true"
      >
        <b-form-group label="Title" label-for="class_title" label-class="form-label">
          <b-form-input
            id="class_title"
            placeholder="Enter Class Title"
            v-model="homepageConfig.class_title"
            required
          />
        </b-form-group>

        <b-form-group label="Description" label-for="class_desc" label-class="form-label">
          <b-form-textarea id="class_desc" rows="6" v-model="homepageConfig.class_desc"></b-form-textarea>
        </b-form-group>

        <b-form-group
          label="Video"
          label-for="classes_video"
          label-class="form-label"
          class="row-align-items-center"
          v-if="!homepageConfig.classesVideoUrl"
        >
          <b-media class="align-items-center" vertical-align="center">
            <b-form-file
              id="classes_video"
              placeholder="Select Video"
              :browse-text="$t('generalMsgs.browse')"
              v-model="uploadFile.classes_video"
              @input="uploadImage('classes_video')"
              :disabled="isVideoUploading"
              accept="video/mp4, video/webm"
            />
          </b-media>
        </b-form-group>

        <b-form-group
          v-if="homepageConfig.classesVideoUrl"
          label="Classes Video"
          label-for="classes_video"
          label-class="form-label"
        >
          <div class="d-flex justify-content-end mb-3">
            <b-btn variant="danger" @click="homepageConfig.classesVideoUrl = ''"> Remove </b-btn>
          </div>
          <video width="100%" height="200" controls>
            <source :src="homepageConfig.classesVideoUrl" :type="`video/${videoType}`" />
          </video>
        </b-form-group>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'testimonials-section'" class="accordion__toggle p-0">
          <span class="flex">Testimonials</span>
          <md-icon class="accordion__toggle-icon">
            {{ testimonialsVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="testimonials-section"
        accordion="testimonialsAccordionId"
        :visible="testimonialsVisible"
        class="accordion__menu"
        @hide="testimonialsVisible = false"
        @show="testimonialsVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="testimonials" v-model="homepageConfig.visibleSections.testimonials" switch>
            Enable this section
          </b-form-checkbox>
        </b-form-group>

        <div v-if="homepageConfig.visibleSections.testimonials">
          <b-form-group label="Layout" label-for="prog_layout" label-class="form-label">
            <div class="row" style="margin-left: -22px">
              <div class="col-md-4" v-for="layout in testimonialsLayoutOptions" :key="layout.image">
                <div class="layout-hover column text-center clickable-item">
                  <figure>
                    <img
                      width="100%"
                      height="125px"
                      :src="getImgUrl(layout.image)"
                      @click.prevent="() => (testimonialsLayout = layout.value)"
                      :alt="testimonialsLayout"
                      :class="testimonialsLayout === layout.value ? 'border-outline-primary' : ''"
                      :style="
                        testimonialsLayout === layout.value ? '' : 'border: #f2f2f2 2px solid; border-radius: 10px;'
                      "
                    />
                  </figure>
                </div>
              </div>
            </div>
          </b-form-group>

          <b-form-group label="Title" label-for="testimonial_title" label-class="form-label">
            <b-form-input
              id="testimonial_title"
              placeholder="Enter Testimonial Title"
              v-model="homepageConfig.testimonial_title"
              required
            />
          </b-form-group>
        </div>
      </b-collapse>

      <page-separator>
        <b-link v-b-toggle="'bottom-section'" class="accordion__toggle p-0">
          <span class="flex">Bottom Section</span>
          <md-icon class="accordion__toggle-icon">
            {{ bottomVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="bottom-section"
        accordion="bottomAccordionId"
        :visible="bottomVisible"
        class="accordion__menu"
        @hide="bottomVisible = false"
        @show="bottomVisible = true"
      >
        <b-form-group label-class="form-label">
          <b-form-checkbox id="bottom_section" v-model="homepageConfig.visibleSections.bottom_section" switch>
            Enable this section
          </b-form-checkbox>
        </b-form-group>

        <div v-if="homepageConfig.visibleSections.bottom_section">
          <b-form-group label="Title" label-for="bottom_title" label-class="form-label">
            <b-form-input id="bottom_title" placeholder="Enter Title" v-model="homepageConfig.bottom_title" required />
          </b-form-group>

          <b-form-group label="Description" label-for="bottom_desc" label-class="form-label">
            <b-form-textarea id="bottom_desc" rows="6" v-model="homepageConfig.bottom_desc" required></b-form-textarea>
          </b-form-group>

          <b-form-group label-class="form-label">
            <b-form-checkbox id="bottomCtaBtn" v-model="homepageConfig.ctaBottomBtn" switch>
              show CTA button?
            </b-form-checkbox>
          </b-form-group>

          <div v-if="homepageConfig.ctaBottomBtn">
            <b-form-group label="CTA Title" label-for="title" label-class="form-label">
              <b-form-input
                id="cta_title"
                placeholder="Enter CTA Title"
                v-model="homepageConfig.ctaBottomTitle"
                required
              />
            </b-form-group>

            <b-form-group label-class="form-label">
              <b-form-checkbox id="bottomCtaLinkBtn" v-model="homepageConfig.ctaBottomLinkStatus" switch>
                Use default enrollment link
                <span style="color: #c5c5c5d6">
                  ({{ get(getLoggedInUser, 'linked_entity.subdomain.subdomain_url') }})
                </span>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              v-if="!homepageConfig.ctaBottomLinkStatus"
              label="Enrollment Link"
              label-for="enrollment_link"
              label-class="form-label"
            >
              <b-form-input
                id="bottom_cta_link"
                placeholder="Enter enrollment link"
                v-model="homepageConfig.ctaBottomLink"
                required
              />
            </b-form-group>
          </div>
        </div>
      </b-collapse>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<style lang="css" scoped>
.column {
  margin: 10px 10px 0;
  padding: 0;
}
.layout-hover figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.layout-hover figure:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
</style>

<script>
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS, HOMEPAGE_ATTRIB_TYPE } from '@/common/constants';
import { requiredIf, required } from 'vuelidate/lib/validators';
import vSelect from 'vue-select';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    FmvAvatar,
    vSelect,
    PageSeparator,
    ImageUploader,
  },
  extends: Page,
  data() {
    return {
      topBannerVisible: true,
      statsVisible: true,
      expertiseVisible: true,
      featuresVisible: true,
      programsVisible: true,
      classesVisible: true,
      testimonialsVisible: true,
      bottomVisible: true,
      SCHOOL_PAYMENT_PLANS,
      title: 'Configure Home Page',
      isAdded: false,
      videoType: 'mp4',
      school: null,
      dragging: false,
      homepageConfig: {
        title: '',
        description: '',
        banner_img_url: '',
        classesVideoUrl: '',
        ctaBtn: true,
        ctaTitle: '',
        ctaLinkStatus: null,
        ctaLink: null,
        attrib_type: [],
        bannerOverlay: true,
        prog_title: '',
        prog_desc: '',
        class_title: '',
        class_desc: '',
        testimonial_title: '',
        testimonial_desc: '',
        bottom_title: '',
        bottom_desc: '',
        bottom_img_url: '',
        expertise_title: '',
        expertise_desc: '',
        stats_title: '',
        stats_desc: '',
        feature_title: '',
        feature_desc: '',
        bottom_short_title: '',
        ctaBottomBtn: true,
        ctaBottomTitle: '',
        ctaBottomLinkStatus: null,
        ctaBottomLink: null,
        visibleSections: {
          stats: false,
          features: false,
          expertise: true,
          testimonials: true,
          bottom_section: true,
        },
      },
      options: [
        { item: 'stats', name: 'Stats' },
        { item: 'feature', name: 'Feature' },
      ],
      linkedWebsite: null,
      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,
      isImgUploading: false,
      isBottomImgUploading: false,
      isVideoUploading: false,
      uploadFile: { banner_image: null, bottom_img: null, classes_video: null },
      uploadPercent: { banner_image: 0, bottom_img: 0, classes_video: 0 },
      uploadCancelTokenSource: { banner_image: null, bottom_img: 0, classes_video: 0 },
      USER_ROLES,
      USER_ROLE_TYPES,
      tags: [{ title: '' }],
      stats: [
        {
          count: 0,
          title: '',
        },
      ],
      features: [
        {
          title: '',
          description: '',
          image_url: '',
        },
      ],
      featuresFile: [{ image_url: null, isImgUploading: false }],
      HOMEPAGE_ATTRIB_TYPE,
      textAlignOptions: [
        { text: 'left', icon: 'format_align_left', value: 'left' },
        { text: 'center', icon: 'format_align_center', value: 'center' },
        { text: 'right', icon: 'format_align_right', value: 'right' },
      ],
      bannerSelectedAlignment: 'left',
      progLayout: 'Layout1',
      testimonialsLayout: 'Layout1',
      progLayoutOptions: [
        { text: 'Layout 1', image: 'prog_layout1.png', value: 'Layout1' },
        { text: 'Layout 2', image: 'prog_layout2.png', value: 'Layout2' },
      ],
      testimonialsLayoutOptions: [
        { text: 'Layout 1', image: 'testimonial_layout1.png', value: 'Layout1' },
        { text: 'Layout 2', image: 'testimonial_layout2.png', value: 'Layout2' },
      ],
      programSelectedAlignment: 'left',
      programsCount: 6,
    };
  },
  validations() {
    return {
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
      stats: {
        required: requiredIf(() => this.homepageConfig.visibleSections.stats),
        $each: {
          count: { required: requiredIf(() => this.homepageConfig.visibleSections.stats) },
          title: { required: requiredIf(() => this.homepageConfig.visibleSections.stats) },
        },
      },
      tags: {
        required,
        $each: { title: { required: requiredIf(() => this.homepageConfig.visibleSections.expertise) } },
      },
      features: {
        required: requiredIf(() => this.homepageConfig.visibleSections.features),
        $each: {
          title: { required: requiredIf(() => this.homepageConfig.visibleSections.features) },
          image_url: { required: requiredIf(() => this.homepageConfig.visibleSections.features) },
        },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    isFormLoading() {
      return (
        this.isLoading ||
        this.isVideoUploading ||
        this.isImgUploading ||
        this.areSchoolsLoading ||
        this.isFooterImgLoading
      );
    },
  },

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', ['getHomePageConfig', 'createHomepageConfig', 'getLinkedImageUploadPresignedUrl']),
    get,
    isEmpty,

    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },

    setFeaturesImage(file, index) {
      this.featuresFile[index].image_url = file;
      this.uploadImage('image_url', index);
    },

    getImgUrl(pic) {
      return require('@/assets/images/webConfig/' + pic);
    },
    setAttribute(value) {
      if (!value.length) {
        this.featuresFile = [{ image_url: null, isImgUploading: false }];
        this.features = [
          {
            title: '',
            description: '',
            image_url: '',
          },
        ];
        this.stats = [
          {
            count: 0,
            title: '',
          },
        ];
      } else if (!this.homepageConfig.visibleSections.stats) {
        this.stats = [
          {
            count: 0,
            title: '',
          },
        ];
      } else if (!this.homepageConfig.visibleSections.features) {
        this.featuresFile = [{ image_url: null, isImgUploading: false }];
        this.features = [
          {
            title: '',
            description: '',
            image_url: '',
          },
        ];
      }
    },
    addStat(index) {
      this.stats.splice(index + 1, 0, {
        count: 0,
        title: '',
      });
    },

    removeStat(index) {
      this.stats.splice(index, 1);
    },
    addTag(index) {
      this.tags.splice(index + 1, 0, {
        title: '',
      });
    },

    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addFeat(index) {
      this.features.splice(index + 1, 0, {
        title: '',
        description: '',
        image_url: '',
      });
      this.featuresFile.splice(index + 1, 0, {
        image_url: null,
        isImgUploading: false,
      });
    },

    removeFeat(index) {
      this.features.splice(index, 1);
      this.featuresFile.splice(index, 1);
    },
    resetData() {
      this.linkedWebsite = null;
      this.homepageConfig.title = '';
      this.homepageConfig.description = '';
      this.homepageConfig.banner_img_url = '';
      this.homepageConfig.ctaBtn = true;
      this.homepageConfig.ctaTitle = '';
      this.homepageConfig.bannerOverlay = true;
      this.homepageConfig.ctaLinkStatus = null;
      this.homepageConfig.ctaLink = null;
      this.homepageConfig.attrib_type = [];
      this.homepageConfig.prog_title = '';
      this.homepageConfig.prog_desc = '';
      this.homepageConfig.class_title = '';
      this.homepageConfig.class_desc = '';
      this.homepageConfig.classesVideoUrl = '';

      this.homepageConfig.testimonial_title = '';
      this.homepageConfig.testimonial_desc = '';
      this.homepageConfig.bottom_title = '';
      this.homepageConfig.bottom_desc = '';
      this.homepageConfig.bottom_img_url = '';
      this.homepageConfig.expertise_title = '';
      this.homepageConfig.expertise_desc = '';
      this.homepageConfig.stats_title = '';
      this.homepageConfig.stats_desc = '';
      this.homepageConfig.feature_title = '';
      this.homepageConfig.feature_desc = '';
      this.homepageConfig.bottom_short_title = '';
      this.homepageConfig.ctaBottomBtn = true;
      this.homepageConfig.ctaBottomTitle = '';
      this.homepageConfig.ctaBottomLinkStatus = null;
      this.homepageConfig.ctaBottomLink = null;
      this.homepageConfig.visibleSections = {
        stats: false,
        features: false,
        expertise: true,
        testimonials: true,
        bottom_section: true,
      };
      this.tags = [{ title: '' }];
      this.featuresFile = [{ image_url: null, isImgUploading: false }];
      this.features = [
        {
          title: '',
          description: '',
          image_url: '',
        },
      ];
      this.stats = [
        {
          count: 0,
          title: '',
        },
      ];
      this.featuresFile = [{ image_url: null, isImgUploading: false }];
      this.features = [
        {
          title: '',
          description: '',
          image_url: '',
        },
      ];
      this.stats = [
        {
          count: 0,
          title: '',
        },
      ];
    },
    prefillLogo(schoolId) {
      this.resetData();
      this.fetchBasicConfig(schoolId);
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async fetchBasicConfig(school_id = null) {
      let resp;
      if (school_id) {
        resp = await this.getHomePageConfig({
          school_id: school_id,
        });
      } else {
        resp = await this.getHomePageConfig({});
      }
      this.linkedWebsite = resp.linked_website;
      this.homepageConfig.title = resp.banner_title;
      this.homepageConfig.description = resp.banner_desc;
      this.homepageConfig.banner_img_url = resp.banner_img;
      this.homepageConfig.ctaBtn = resp.banner_cta_status;
      this.homepageConfig.ctaTitle = resp.banner_cta_title;
      this.homepageConfig.bannerOverlay = resp.banner_overlay;
      this.bannerSelectedAlignment = get(resp, 'banner_text_align') ? get(resp, 'banner_text_align') : 'left';

      this.programSelectedAlignment = get(resp, 'prog_text_align') ? get(resp, 'prog_text_align') : 'left';
      this.progLayout = resp.program_layout;
      this.programsCount = resp.num_of_programs;
      this.testimonialsLayout = resp.testimonial_layout;
      this.homepageConfig.ctaLinkStatus = resp.banner_cta_link === null ? true : false;
      this.homepageConfig.ctaLink = resp.banner_cta_link;
      this.homepageConfig.attrib_type = resp.attrib_type ? resp.attrib_type : [];
      this.homepageConfig.prog_title = resp.prg_title;
      this.homepageConfig.prog_desc = resp.prg_desc;
      this.homepageConfig.class_title = resp.class_title;
      this.homepageConfig.class_desc = resp.class_desc;
      this.homepageConfig.classesVideoUrl = resp.class_video_url;

      this.homepageConfig.testimonial_title = resp.testimonial_title;
      this.homepageConfig.testimonial_desc = resp.testimonial_desc;
      this.homepageConfig.bottom_title = resp.bottom_title;
      this.homepageConfig.bottom_desc = resp.bottom_desc;
      this.homepageConfig.bottom_img_url = resp.bottom_img;
      this.homepageConfig.expertise_title = resp.expertise_title;
      this.homepageConfig.expertise_desc = resp.expertise_desc;
      this.homepageConfig.stats_title = resp.stats_title;
      this.homepageConfig.stats_desc = resp.stats_desc;
      this.homepageConfig.feature_title = resp.feature_title;
      this.homepageConfig.feature_desc = resp.feature_desc;
      this.homepageConfig.bottom_short_title = resp.bottom_short_title;
      this.homepageConfig.ctaBottomBtn = resp.bottom_cta_status;
      this.homepageConfig.ctaBottomTitle = resp.bottom_cta_title;
      this.homepageConfig.ctaBottomLinkStatus = resp.bottom_cta_link === null ? true : false;
      this.homepageConfig.ctaBottomLink = resp.bottom_cta_link;
      this.homepageConfig.visibleSections = resp.visible_sections;

      this.options;
      this.stats = get(resp, 'attribs.stats.length')
        ? get(resp, 'attribs.stats')
        : [
            {
              count: 0,
              title: '',
            },
          ];
      this.features = get(resp, 'attribs.features.length')
        ? get(resp, 'attribs.features')
        : [
            {
              title: '',
              description: '',
              image_url: '',
            },
          ];
      if (get(resp, 'attribs.features')) {
        this.featuresFile = this.features.map(() => ({ image_url: null, isImgUploading: false }));
      }
      this.tags = get(resp, 'attrib_tags.length') ? resp.attrib_tags.map((t) => ({ title: t })) : [{ title: '' }];
    },
    async uploadImage(uploadType, index) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      if (uploadType === 'banner_image') {
        this.isImgUploading = true;
      } else if (uploadType === 'bottom_img') {
        this.isBottomImgUploading = true;
      } else if (uploadType === 'classes_video') this.isVideoUploading = true;
      else this.featuresFile[index].isImgUploading = true;
      try {
        const urlResp = await this.getLinkedImageUploadPresignedUrl({
          file_name:
            uploadType === 'image_url' ? this.featuresFile[index].image_url.name : this.uploadFile[uploadType].name,
          content_type:
            uploadType === 'image_url' ? this.featuresFile[index].image_url.type : this.uploadFile[uploadType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: uploadType === 'image_url' ? this.featuresFile[index].image_url : this.uploadFile[uploadType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'banner_image') {
          this.homepageConfig.banner_img_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'image_url') {
          this.features[index].image_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'bottom_img') {
          this.homepageConfig.bottom_img_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'classes_video') {
          this.homepageConfig.classesVideoUrl = urlResp.upload_url.split('?')[0];
          const makeType = this.homepageConfig.classesVideoUrl.split('.');
          this.videoType = makeType[makeType.length - 1];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'banner_image') this.isImgUploading = false;
      else if (uploadType === 'bottom_img') this.isBottomImgUploading = false;
      else if (uploadType === 'classes_video') this.isVideoUploading = false;
      else this.featuresFile[index].isImgUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            linked_website: this.linkedWebsite,
            banner_title: this.homepageConfig.title,
            banner_desc: this.homepageConfig.description,
            banner_img: this.homepageConfig.banner_img_url,
            banner_overlay: this.homepageConfig.bannerOverlay,
            banner_cta_status: this.homepageConfig.ctaBtn,
            banner_cta_title: this.homepageConfig.ctaTitle,
            banner_cta_link: this.homepageConfig.ctaLinkStatus ? null : this.homepageConfig.ctaLink,
            banner_text_align: this.bannerSelectedAlignment,
            attrib_type: this.homepageConfig.attrib_type,
            attribs: {
              stats: this.homepageConfig.visibleSections.stats ? this.stats : [],
              features: this.homepageConfig.visibleSections.features ? this.features : [],
            },
            bottom_title: this.homepageConfig.bottom_title,
            bottom_desc: this.homepageConfig.bottom_desc,
            bottom_img: this.homepageConfig.bottom_img_url,

            bottom_cta_status: this.homepageConfig.ctaBottomBtn,
            bottom_cta_title: this.homepageConfig.ctaBottomTitle,
            bottom_cta_link: this.homepageConfig.ctaBottomLinkStatus ? null : this.homepageConfig.ctaBottomLink,

            stats_title: this.homepageConfig.stats_title,
            stats_desc: this.homepageConfig.stats_desc,
            feature_title: this.homepageConfig.feature_title,
            feature_desc: this.homepageConfig.feature_desc,
            prg_title: this.homepageConfig.prog_title,
            prg_desc: this.homepageConfig.prog_desc,

            prog_text_align: this.programSelectedAlignment,
            program_layout: this.progLayout,
            num_of_programs: this.programsCount,
            testimonial_layout: this.testimonialsLayout,
            testimonial_title: this.homepageConfig.testimonial_title,
            testimonial_desc: this.homepageConfig.testimonial_desc,
            class_title: this.homepageConfig.class_title,
            class_desc: this.homepageConfig.class_desc,
            class_video_url: this.homepageConfig.classesVideoUrl,
            bottom_short_title: this.homepageConfig.bottom_short_title,
            expertise_title: this.homepageConfig.expertise_title,
            expertise_desc: this.homepageConfig.expertise_desc,
            attrib_tags: this.tags.filter((tag) => !!tag.title).map((tag) => tag.title),
            visible_sections: this.homepageConfig.visibleSections,
            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
          };
          await this.createHomepageConfig({
            ...data,
          });
          this.makeToast({ variant: 'success', msg: 'Settings Configured!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
